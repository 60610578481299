<template>
  <tree-user
    class="aside-tree"
    simple-search
    :checkbox="false"
    :drawerDisable="true"
    :show-object-count.sync="showObjectCount"
    @click="setCurrentHold">
  </tree-user>
</template>

<script>
import TreeUser from '@/views/common/tree-aside'
import { mapMutations } from 'vuex'
export default {
  name: 'asideTree',
  components: {
    TreeUser
  },
  computed: {
  },
  data() {
    return {
      showObjectCount: false
    }
  },
  created() {
    this.addCachedTrees('asideTree')
  },
  methods: {
    setCurrentHold(holdID, name) {
      this.setCurrentHoldId(holdID)
      this.setCurrentHoldName(name)
    },
    ...mapMutations({
      setCurrentHoldId: 'SET_CURRENT_HOLD_ID',
      setCurrentHoldName: 'SET_CURRENT_HOLD_NAME',
      addCachedTrees: 'ADD_CACHED_TREES'
    })
  }
}
</script>

<style lang="scss" scoped>
$hightlight-color: #575C5F;
.aside-tree {
  & ::v-deep .el-tree-node {
    > .el-tree-node__content:hover {
      background-color:$hightlight-color;
    }
  }
  & ::v-deep .el-tree-node.is-current {
    > .el-tree-node__content {
      background-color:$hightlight-color;

      > .el-tree-node__label {
        color: var(--color-p);
      }
    }
  }
}
</style>
